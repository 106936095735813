.Collapsible {
  background-color: $white;
  .Collapsible__trigger {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background-color: rgba($color: $twinkle_blue, $alpha: 0.25);
    padding: 0.75rem 1rem;
  }
  .Annotations {
    background-color: white;
    padding: 1rem 0rem;
    padding-bottom: 0;
    .annotationItem {
      .annotationItemContent {
        flex: 1 1 auto;
        margin-bottom: 1rem;
        .basicData {
          display: flex;
          justify-content: space-between;
          background-color: $baby_powder;
          overflow: hidden;
          border-radius: 0.25rem;
          .minuteLabel {
            text-decoration: none;
            cursor: initial;
            .btn {
              @extend .btn;
              border-radius: 0;
              height: 100%;
              padding: 0rem 1rem;
              align-items: center;
            }
          }
          .annotationData {
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 auto;
            padding: 1rem;
            .annotationName {
              display: flex;
              flex: 1 1 100%;
              padding-top: 0.25rem;
              margin-bottom: 0.5rem;
              hyphens: auto;
              word-break: break-all;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
