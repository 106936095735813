button.btn {
  outline: none;
  box-shadow: none;
  border: none;
  // border-radius: 0.25em;
}
.btn {
  display: inline-flex;
  color: $white_dirty;
  background-color: $gunmetal;
  padding: 0.5rem 0.8rem;
  white-space: nowrap;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  border-radius: 0.25em;

  &.drag {
    cursor: ns-resize;
  }

  &_fluid {
    @extend .btn;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  &.disabled {
    @extend .btn;
    background-color: gray;

    &:active {
      background-color: gray !important;
    }

    &:focus {
      outline: 0;
      background-color: gray !important;
    }

    &:hover {
      background-color: gray !important;
    }

    &:visited {
      background-color: gray;
    }
  }

  &.success {
    @extend .btn;
    background-color: $algal_fuel;

    &:active {
      background-color: $reptile_green !important;
    }

    &:focus {
      outline: 0;
      background-color: $reptile_green !important;
    }

    &:hover {
      background-color: $reptile_green !important;
    }

    &:visited {
      background-color: $algal_fuel;
    }
  }

  &.warning {
    @extend .btn;
    background-color: $nyc_taxi;

    &:active {
      background-color: $flirtatious !important;
    }

    &:focus {
      outline: 0;
      background-color: $flirtatious !important;
    }

    &:hover {
      background-color: $flirtatious !important;
    }

    &:visited {
      background-color: $nyc_taxi;
    }
  }

  &.info {
    @extend .btn;
    background-color: $turquoise_topaz !important;
    background: $turquoise_topaz !important;

    &:active {
      background-color: $maximum_blue_green !important;
    }

    &:focus {
      outline: 0;
      background-color: $maximum_blue_green !important;
    }

    &:hover {
      background-color: $maximum_blue_green !important;
    }

    &:visited {
      background-color: $turquoise_topaz;
    }

    &_border{
      background-color: transparent;
      border: 2px solid $turquoise_topaz;
      color: $turquoise_topaz;
  
      &:active {
        border: 2px solid $maximum_blue_green !important;
        background-color: transparent !important;
      }
  
      &:focus {
        outline: 0;
        border: 2px solid $maximum_blue_green !important;
        background-color: transparent !important;
      }
  
      &:hover {
        border: 2px solid $maximum_blue_green !important;
        background-color: $turquoise_topaz !important;
        color: $white;
      }
  
      &:visited {
        border: 2px solid $turquoise_topaz;
        background-color: transparent;
      }
    }
  }

  &.label {
    @extend .btn;
    cursor: auto;

    &:focus {
      outline: 0;
    }
    &:hover{
      background-color: $primary !important;
    }
    &.noClick:hover{
      background-color: initial;
    }
  }

  &.danger {
    @extend .btn;
    background-color: $desire;

    &:active {
      background-color: $fusion_red !important;
    }

    &:focus {
      outline: 0;
      background-color: $fusion_red !important;
    }

    &:hover {
      background-color: $fusion_red !important;
    }

    &:visited {
      background-color: $desire;
    }
  }

  &.light {
    @extend .btn;
    background-color: $innuendo;

    &:active {
      background-color: $twinkle_blue !important;
    }

    &:focus {
      outline: 0;
      background-color: $twinkle_blue !important;
    }

    &:hover {
      background-color: $twinkle_blue !important;
    }

    &:visited {
      background-color: $innuendo;
    }
  }

  &.link {
    @extend .btn;
    color: $gunmetal;
    background-color: transparent;

    &:active {
      color: $primary !important;
      background-color: transparent;
    }

    &:focus {
      outline: 0;
      color: $primary !important;
      background-color: transparent;
    }

    &:hover {
      color: $primary !important;
      background-color: transparent;
    }

    &:visited {
      color: $gunmetal !important;
      background-color: transparent;
    }
  }

  &.boyzone {
    @extend .btn;
    background-color: $primary;

    &:active {
      background-color: $primary !important;
    }

    &:focus {
      outline: 0;
      background-color: $primary !important;
    }

    &:hover {
      background-color: $primary !important;
    }

    &:visited {
      background-color: $primary;
    }
  }

  &:active {
    background-color: $raisin_black !important;
  }

  &:focus {
    outline: 0;
    background-color: $raisin_black !important;
  }

  &:hover {
    background-color: $raisin_black !important;
  }

  &:visited {
    background-color: $gunmetal;
  }
}

.smBtn {
  text-decoration: none;
  margin-right: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
  margin-bottom: 0.5em;
  cursor: pointer;
}
.editBtn {
  @extend .smBtn;
  color: $nyc_taxi;
  transition: 0.2s ease-in-out;

  &:hover {
    color: $primary;
  }
}
.logoutBtn {
  @extend .smBtn;
  color: $white;
  transition: 0.2s ease-in-out;

  &:hover {
    color: $white_gray;
  }
}
.removeBtn {
  @extend .smBtn;
  color: $desire;
  transition: 0.2s ease-in-out;

  &:hover {
    color: $primary;
  }
}
.infoBtn {
  @extend .smBtn;
  color: $turquoise_topaz;
  transition: 0.2s ease-in-out;

  &:hover {
    color: $primary;
  }
}
.addBtn {
  @extend .smBtn;
  color: $algal_fuel;
  transition: 0.2s ease-in-out;

  &:hover {
    color: $primary;
  }
}

.actionlistBtn {
  @extend .smBtn;
  color: $gunmetal;
  transition: 0.2s ease-in-out;

  &:hover {
    color: $primary;
  }
}
