//BASIC
.clipCard {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  border-radius: 0.25em;
  overflow: hidden;
  margin-bottom: 1em;
  @include mobile {
    padding-left: 0;
    padding-right: 0;
  }
  .innerData {
    flex-wrap: wrap;
    flex: 1 1;
    padding: 1em;
    background-color: $baby_powder;
    .Label {
      color: $primary;
    }
    .buttonArea {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
      small{
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  .pageChanger {
    display: flex;
    max-width: max-content;
    a{
      margin-right: 0;
      margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  .childs {
    flex: 1 1 100%;
    @include mobile {
      // padding-left: calc(1em + 40px);
      padding-left: 1em;
      padding-right: 1em;
    }
  }
}
//BASIC

//MATCHSITE
.clipSite {
  .clipCard {
    padding-left: 0;
    padding-right: 0;
  }
}
//MATCHSITE

//MATCHDETAILS
.clipDetails {
  .clipCard {
    border-radius: 0em;
    .innerData {
      padding-left: 0;
      padding-right: 0;
      background-color: unset;
      @include mobile {
        // padding-left: calc(1em + 40px);
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }
}
//MATCHDETAILS
