.sequenceItem {
  overflow: hidden;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  .sequenceItemContent {
    flex: 1 1 auto;
    .basicData {
      display: flex;
      justify-content: space-between;
      background-color: $baby_powder;
      overflow: hidden;
      .minuteLabel {
        text-decoration: none;
        cursor: initial;
        .btn {
          @extend .btn;
          border-radius: 0;
          height: 100%;
          padding: 0rem 1rem;
          align-items: center;
          margin: 0;
        }
        &.draggableHeader {
          cursor: ns-resize;
        }
      }
      .sequenceData {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        padding: 0.5em;
        &.list {
          padding: 0;
          padding-top: 0.5em;
          .LabelList{
            padding-left: 0.5em;
            padding-right: 0.5em;
            padding-bottom: 0.5em;
          }
          .callToAction{
            margin-top: 0;
            padding-left: 1em;
            padding-right: 1em;
            background-color: rgba($color: $twinkle_blue, $alpha: 0.15);
            border-top: 2px solid $primary;
            a{
              margin-top: 0.75em;
              margin-bottom: 0.75em;
            }
            span{
              margin-top: 0.75em;
              margin-bottom: 0.75em;
            }
          }
        }
        .draggableHeader {
          display: flex;
          flex: 1 1 100%;
          color: initial;
        }
        .sequenceName {
          display: flex;
          flex: 1 1 100%;
          padding-top: 0.25rem;
          margin-bottom: 0.5rem;
          hyphens: auto;
          word-break: break-all;
          transition: 0.2s ease-in-out;
          cursor: pointer;
          @media (max-width: 990px) {
            margin-bottom: 1rem;
          }
          &:hover {
            color: $primary;
          }
        }
        .callToAction {
          display: flex;
          flex-wrap: wrap;
          flex: 1 1 100%;
          margin-top: 0.5em;
          small {
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
