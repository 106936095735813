.pathMap {
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  color: white;
  ul {
    list-style-type: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 0px;
    .navItemBack {
      padding: 0.5rem 1rem;
      font-weight: 300 !important;
      text-transform: uppercase;
      text-decoration: none;
      color: white;
      cursor: pointer;
    }
    .navItem {
      display: flex;
      flex: 1 1 auto;
      color: $baby_powder;
      text-decoration: none;
      align-items: center;
      text-transform: uppercase;
      padding: 0rem 1rem;
      padding-top: 0;
      font-weight: 700 !important;
      margin-top: 0.25rem;
      color: $primary;
      .navIcon {
        margin-right: 0.5rem;
      }
    }
  }
}
