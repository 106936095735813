.baseNav {
    margin-bottom: 1rem;
  ul {
    list-style-type: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 0px;
    .navItem {
      display: flex;
      flex: 1 1 auto;
      color: $baby_powder;
      text-decoration: none;
      align-items: center;
      color: $white;
      text-transform: uppercase;
      padding: 0.5rem 1rem;
      font-weight: 300;
      transition: 0.2s ease-in-out;
      border-left: 3px solid transparent;
      &:hover{
        background-color: rgba($color: $innuendo, $alpha: 0.10);
        border-left: 3px solid $primary;
      }
      .navIcon {
        margin-right: 0.5rem;
      }
    }
  }
}
  