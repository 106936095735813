.OmniFilter {
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: $white;
  border: 1px solid $white_gray;
  margin-bottom: 1em;
  .FilterTagBar {
    display: flex;
    input {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;
      padding: 0.8rem 1rem;
      border: 0;
      font-size: 16px;
    }
  }
  .suggestionBox {
    max-height: 200px;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    .getSuggestionsResults {
      padding: 0.5rem 1rem;
      display: flex;
      flex-basis: 100%;
      cursor: pointer;
      align-items: center;
      border-top: 1px solid $white_gray;
      transition: 0.2s ease-in-out;
      &.selected{
        background-color: $white_gray;
      }
      &:hover {
        background-color: $white_dirty;
      }
      .showLabel {
        padding: 0.25em 0.5em;
        border-radius: 0.25rem;
        font-weight: 500;
      }
      .showInput,
      .showOption {
        color: $gunmetal;
        padding-left: 1rem;
      }
    }
  }
}
.activefilter {
  margin-bottom: 0.5rem;
}
.FilterTag {
  display: inline-flex;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
  cursor: pointer;
  .labelTag {
    font-weight: 500;
    padding-right: 0.5rem;
  }
}