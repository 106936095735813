html {
  @include fontSize;
  -webkit-text-size-adjust: none;
}

body {
  font-family: 'Nekst';
  font-weight: 400;
  line-height: 1.45;
}

p {margin-bottom: 1.25em;}

h1, h2, h3, h4, h5 {
  margin: 0 0 0.5rem;
  font-family: 'Nekst';
  font-weight: 400;
  line-height: 1.15;
}

h1 {
  margin-top: 0;
  font-size: 2.488em;
}

h2 {font-size: 2.074em;}

h3 {font-size: 1.728em;}

h4 {font-size: 1.44em;}

h5 {font-size: 1.2em;}

small {font-size: 0.833em;}

.siteHeader{
  margin-top: 1em;
  margin-bottom: 1em;
  @include mobile{
    margin-top: 3em;  
  }
}
@font-face {
  font-family:"Nekst";
  src:url("https://storage.googleapis.com/aisw-assets/fanpage/fonts/Nekst/Nekst-Thin.otf") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
  font-family:"Nekst";
  src:url("https://storage.googleapis.com/aisw-assets/fanpage/fonts/Nekst/Nekst-Light.otf") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
  font-family:"Nekst";
  src:url("https://storage.googleapis.com/aisw-assets/fanpage/fonts/Nekst/Nekst-Regular.otf") format("opentype");
  font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
  font-family:"Nekst";
  src:url("https://storage.googleapis.com/aisw-assets/fanpage/fonts/Nekst/Nekst-Medium.otf") format("opentype");
  font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
  font-family:"Nekst";
  src:url("https://storage.googleapis.com/aisw-assets/fanpage/fonts/Nekst/Nekst-SemiBold.otf") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
  font-family:"Nekst";
  src:url("https://storage.googleapis.com/aisw-assets/fanpage/fonts/Nekst/Nekst-Bold.otf") format("opentype");
  font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
}

@font-face {
  font-family:"Nekst";
  src:url("https://storage.googleapis.com/aisw-assets/fanpage/fonts/Nekst/Nekst-Black.otf") format("opentype");
  font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}