.coachingtool.theme-a.dark#Coachingtool {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $white;
  body {
    height: 100%;
    width: 100%;
    overflow: auto;
    margin: 0;
    padding: 0;
    background-color: $white;
    #root {
      color: $gunmetal;
      background-color: $white;
      height: 100%;
      .gatekeeper {
        margin: 0;
        height: 100%;
        .dashboard {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $gunmetal;
          // background-image: url("https://images.pexels.com/photos/1667583/pexels-photo-1667583.jpeg");
          // background-image: url("/pexels-photo-1667583.jpeg");
          background-position: center center;
          background-size: cover;
          height: 100vh;
          .intro {
            margin-top: 1em;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            .logoArea {
              width: 100%;
              display: flex;
              justify-content: center;
              .coachingtoolLogo {
                width: auto;
                height: 12rem;
                padding-left: 1rem;
                padding-right: 1rem;
                padding-top: 0.75rem;
                padding-bottom: 2.75rem;
              }
            }
            .welcome {
              width: 100%;
              text-align: center;
              margin-top: 1em;
              margin-bottom: 4em;
              color: $white;
            }
            .entry {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
          .logout {
            position: fixed;
            text-align: center;
            top: 0;
            right: 0;
            margin: 0;
            border-radius: 0;
            border-bottom-left-radius: 0.25em;
          }
        }
        .outerContent {
          max-width: calc(100% - #{$sidebarWidth});
          margin-left: auto;
          transition: 0.2s ease-in-out;
          min-height: 100%;
          padding-bottom: 1em;
          .row {
            .innerContent {
              -webkit-overflow-scrolling: touch;
              max-width: 800px;
              width: 100%;
              margin: 0 auto;
            }
          }
        }
        .matchSite,
        .clipSite {
          .innerContent {
            -webkit-overflow-scrolling: touch;
            padding-left: 1em;
            padding-right: 1em;
          }
        }
        // This is only Editor and the elements of it, its complex. Ask before you change something!
        .editorSite {
          background-color: black;
          position: fixed;
          width: 100%;
          .outerContent {
            padding-top: 0;
            padding-bottom: 0;
            max-width: unset;
            margin-left: unset;
            transition: 0.2s ease-in-out;
            .row {
              .innerContent {
                -webkit-overflow-scrolling: touch;
                max-width: unset;
                margin: unset;
                width: 100%;
                height: 100%;
                .ribbon {
                  display: none;
                }
                #ContextButtons {
                  position: fixed;
                  right: 0;
                  bottom: 5em;
                  padding-right: 1em;
                }
                #QuickbarContainer {
                  left: $sidebarWidth;
                  display: flex;
                  align-items: center;
                  position: fixed;
                  height: max-content;
                  bottom: 5em;
                  text-align: center;
                  transition: 0.2s ease-in-out;
                  .Toolslist {
                    display: flex;
                    font-size: 18px;
                    background-color: $gunmetal;
                    color: white;
                    border-bottom-right-radius: 0.25em;
                    border-top-right-radius: 0.25em;
                    .Tool {
                      height: max-content;
                      padding: 0.5em;
                      transition: 0.2s ease-in-out;
                      min-width: calc(35px - 0.5em);
                      &.selected {
                        background-color: $primary;
                      }
                      &.hidden {
                        display: none;
                      }
                    }
                    .mobiElement {
                      display: none;
                    }
                  }
                  .Toolslist.open {
                    width: 100%;
                  }
                }
                .AnnotationContainer {
                  position: fixed;
                  right: 0;
                  top: 40px;
                  padding-right: 1rem;
                  color: white;
                  .mobiElement {
                    display: none;
                  }
                  .colorButton {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                  }
                }
                .VideoContainer {
                  .video-player {
                    canvas, .loader-container {
                      max-width: calc(100% - #{$sidebarWidth});
                      margin-left: auto;
                      height: calc(100% - 54.78px);
                    }
                    .loader-container {
                      left: #{$sidebarWidth};
                    }
                    .controlsContainer {
                      max-width: calc(100% - #{$sidebarWidth});
                      background: none;
                      right: 0;
                      margin-left: auto;
                      padding: 0;
                      @include mobile {
                        padding: 0 !important;
                      }
                      .controls {
                        background-color: $gunmetal;
                        font-size: initial;
                        grid-template-areas:
                          "progressbar progressbar progressbar progressbar progressbar progressbar progressbar progressbar progressbar progressbar progressbar progressbar"
                          ". playPause sound progressText . . . . show-help playback-rate fullscreen .";
                        grid-row-gap: 7px;
                        @media (max-width: 768px) {
                          height: unset !important;
                        }
                        button {
                          margin-bottom: 5px;
                          margin-top: 5px;
                        }
                        .progressText {
                          @media (max-width: 768px) {
                            font-size: inherit !important;
                          }
                        }
                        svg {
                          @media (max-width: 768px) {
                            font-size: inherit !important;
                          }
                        }
                      }
                    }
                  }
                }
                .VideoContainer.Fullscreen {
                  .video-player {
                    canvas {
                      min-width: 100% !important;
                      width: 100% !important;
                      max-width: 100% !important;
                      margin-left: 0;
                    }
                    .controlsContainer {
                      max-width: 100% !important;
                      width: 100% !important;
                    }
                  }
                }
              }
            }
          }
        }
        // This is only Editor and the elements of it, its complex. Ask before you change something!
      }
    }
    .previewHeader {
      .videoPreview {
        display: flex;
        width: 100%;
        height: 30vh;
        background-position: center center;
        background-size: cover;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        border-bottom: 2px solid $primary;
        border-bottom-right-radius: 0.25em;
        border-bottom-left-radius: 0.25em;
        .previewGameLinkLabel {
          height: max-content;
          width: max-content;
        }
      }
    }
  }
}

.VideoPreviewContainer{
  display: block;
  width: 100%;
  height: 30vh;
  background-color: $gunmetal;
  text-decoration: none;
  border-bottom: 2px solid $primary;
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  .video-player {
    canvas {
      max-width: 100%;
      margin-left: auto;
      // height: 100%;
      height: calc(100% - 55.39px);
    }
    .controlsContainer {
      max-width: 100%;
      background: none;
      right: 0;
      margin-left: auto;
      padding: 0;
      @include mobile {
        padding: 0 !important;
      }
      .controls {
        background-color: $gunmetal;
        font-size: initial;
        grid-template-areas:
          "progressbar progressbar progressbar progressbar progressbar progressbar progressbar progressbar progressbar progressbar progressbar"
          ". playPause sound progressText . . . . . fullscreen .";
        grid-row-gap: 7px;
        @media (max-width: 768px) {
          height: unset !important;
        }
        button {
          margin-bottom: 5px;
          margin-top: 5px;
        }
        .progressText {
          @media (max-width: 768px) {
            font-size: inherit !important;
          }
        }
        svg {
          @media (max-width: 768px) {
            font-size: inherit !important;
          }
        }
      }
    }
  }
}
.VideoPreviewContainer .children {
  width: 100%;
  height: 100%;
}
.VideoPreviewContainer .previewGameLink{
  position: absolute;
  right: 10px;
  bottom: 70px;
}
@media (max-width: 780px) and (max-height: 400px) {
  .VideoPreviewContainer {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    height: 50vh;
  }
}
@include mobile {
  .coachingtool.theme-a.dark#Coachingtool {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    body {
      height: unset;
      width: unset;
      overflow: unset;
      margin: 0;
      #root {
        .useOtherBrowserNotice {
          width: 100%;
        }
        .gatekeeper {
          .outerContent {
            padding: 1em;
            padding-top: env(safe-area-inset-top);
            max-width: 100%;
            margin-left: 0;
            padding-bottom: 6em;
            &.isOpen {
              position: fixed;
              left: $sidebarWidthMobile;
            }
            &.isClosed {
              overflow: initial;
              position: relative;
              left: 0;
            }
            .row {
              .innerContent {
                -webkit-overflow-scrolling: touch;
                width: 100%;
              }
            }
          }
          .matchSite,
          .clipSite {
            .row {
              .innerContent {
                -webkit-overflow-scrolling: touch;
                width: calc(100% - 2em);
                padding-left: 1em;
                padding-right: 1em;
              }
            }
          }
          // This is only Editor and the elements of it, its complex. Ask before you change something!
          .editorSite {
            .outerContent {
              padding: none;
              #ContextButtons {
                bottom: 75.39px;
              }
              #ContextButtons,
              #QuickbarContainer,
              .AnnotationContainer,
              .controlsContainer,
              canvas {
                transition: 0.2s ease-in-out;
              }
              &.isClosed {
                canvas {
                  max-width: 100% !important;
                  margin-left: 0 !important;
                  height: calc(100% - 55.39px);
                }
                .controlsContainer {
                  max-width: 100% !important;
                  margin-left: 0 !important;
                }
                #QuickbarContainer {
                  left: 0px !important;
                }
              }
              &.isOpen {
                left: unset;
                canvas {
                  margin-left: $sidebarWidthMobile !important;
                  max-width: 100% !important;
                }
                .controlsContainer {
                  margin-left: $sidebarWidthMobile !important;
                  max-width: 100% !important;
                }
                #ContextButtons {
                  right: -#{$sidebarWidthMobile} !important;
                }
                .AnnotationContainer {
                  right: -#{$sidebarWidthMobile} !important;
                }
                #QuickbarContainer {
                  left: $sidebarWidthMobile !important;
                }
              }
            }
          }
          // This is only Editor and the elements of it, its complex. Ask before you change something!
        }
      }
    }
  }
}

.useOtherBrowserNotice {
  color: $primary;
  position: fixed;
  display: flex;
  align-items: center;
  width: calc(100% - 2em);
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 9999;
  text-align: center;
  padding: 1em;
  transition: 0.2s ease-in-out;
  margin:0;
}

.VideoPreviewContainer {
  .useOtherBrowserNotice {
    position: initial;
    margin:0;
  }
}

.useLandscapeNotice {
  color: $primary;
}
@media screen and (orientation: portrait) {
  .useLandscapeNotice {
    position: fixed;
    display: flex;
    align-items: center;
    width: calc(100% - 2em);
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    z-index: 9999;
    text-align: center;
    padding: 1em;
    transition: 0.2s ease-in-out;
  }
}
@media screen and (orientation: landscape) {
  .useLandscapeNotice {
    display: none;
  }
}
