.sidebar {
  width: $sidebarWidth;
  height: 100vh;
  overflow: auto;
  position: fixed;
  background-color: $raisin_black;
  transition: 0.2s ease-in-out;
  .logoArea {
    .coachingtoolLogo {
      width: 340px;
      max-width: 400px;
      height: auto;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-top: 0rem;
    }
  }
  @include mobile {
    width: $sidebarWidthMobile;
    &.isOpen {
      margin-left: 0;
      overflow-y: scroll;
    }
    &.isClosed {
      margin-left: -$sidebarWidthMobile;
    }
  }
  .SidebarContainer {
    .clipCard {
      padding-bottom: 5em;
      .innerData {
        @include mobile {
          padding: 1em;
        }
      }
      .childs {
        width: unset;
      }
    }
    .ClipCard {
      padding-bottom: 5em;
    }
    .matchCard{
      padding-bottom: 5em;
      .innerData {
        @include mobile {
          padding: 1em;
        }
      }
    }
    .childs {
      width: 100%;
      .activefilter {
        color: $white;
      }
      .sequenceItem {
        .minuteLabel {
          cursor: pointer;
        }
      }
    }
    .innerData {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      color: $white;
    }
  }
  .clipBox {
    padding: 1rem;
    padding-bottom: 8em;
    .updateClipUi,
    .createClipUi {
      overflow: hidden;
      border-radius: 0.25rem;
      display: inline-flex;
      width: 100%;
      margin-bottom: 1rem;
      input {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        padding: 0.75rem 0.875rem;
        border: 0;
        margin: 0;
        font-size: 16px;
      }
      .btn{
        margin: 0;
      }
      a {
        align-items: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .saveForAction {
        border-radius: 0;
        align-items: center;
      }
      .infoTextForAction{
        color: rgba($color: $white, $alpha: 0.75);
        margin: 0;
      }
    }
    .updateClipUi {
      input {
        &:disabled {
          color: rgba($color: black, $alpha: 0.25);
        }
      }
    }
    .clipBoxContent {
      .sequenceItem {
        padding: 0;
        margin-bottom: 1rem;
        border-radius: 0.25rem;
        overflow: hidden;
        .sequenceItemContent {
          margin-bottom: 0;
          .basicData {
            border-radius: 0;
          }
        }
        .Collapsible {
          flex: 1 1 100%;
          .Collapsible__contentInner {
            background-color: rgba($color: $innuendo, $alpha: 0.15);
            padding: 1em;
          }
        }
      }
    }
    .headerBox {
      .headerLabel {
        display: flex;
        flex: 1 1 auto;
        text-decoration: none;
        align-items: center;
        text-transform: uppercase;
        padding-top: 0;
        font-weight: 700 !important;
        margin-top: 0.25rem;
        margin-bottom: 1rem;
        color: $primary;
      }
    }
  }
  .newClipButton {
    padding: 0rem;
    a {
      border-radius: 0;
    }
  }
  .editClip {
    border-radius: 0;
    margin-bottom: 1rem;
  }
}

.ribbon {
  display: block;
  position: fixed;
  z-index: 10;
  bottom: 0;
  padding-top: 1em;
  padding-bottom: 1.25em;
  background-color: $gunmetal;
  color: $white;
  transition: 0.2s ease-in-out;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  &.isOpen {
    left: $sidebarWidthMobile;
  }
  &.isClosed {
    left: 0;
  }
  @include desktop {
    display: none;
  }
}

.gateOpener {
  display: none;
  position: fixed;
  z-index: 101;
  left: 0;
  top: 40px;
  width: 35px;
  padding: 0.1em;
  text-align: center;
  font-size: 24px;
  background-color: $gunmetal;
  color: $white;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  @media (max-width: 990px) {
    display: unset;
    transition: 0.2s ease-in-out;
    &.isOpen {
      left: $sidebarWidthMobile;
    }
    &.isClosed {
      left: 0;
    }
  }
}

// .DraggableGhostHelper {
//   .sequenceItem {
//     padding: 0;
//     margin-bottom: 1rem;
//     border-radius: 0.25rem;
//     overflow: hidden;
//     .sequenceItemContent {
//       margin-bottom: 0;
//       .basicData {
//         border-radius: 0;
//       }
//     }
//     .Collapsible {
//       flex: 1 1 100%;
//       .Collapsible__contentInner {
//         padding-top: 1em;
//         padding-bottom: 0.5em;
//       }
//     }
//   }
// }

.helpcollapse {
  margin-bottom: 1em;
  .Collapsible {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    background-color: transparent;
    .Collapsible__contentInner {
      margin-top: 1em;
      padding: 1em;
      color: $white;
      border: 1px solid red;
      border-radius: 0.25em;
    }
  }
}


.FastClipButtons{
  margin-bottom: 1.5em;
  .clipBtnText{
    color: $white;
    margin-bottom: 0.25em;
    text-transform: uppercase;
  }
}

.clipBoxContent{
  .sequenceItem .sequenceItemContent .basicData .sequenceData{
    flex-wrap: nowrap;
  }
  .sequenceItem .sequenceItemContent .basicData .sequenceData .callToAction{
    flex: unset;
    margin-top: 0;
  }
  .sequenceItem .sequenceItemContent .basicData .sequenceData .callToAction a{
    margin: 0;
    padding: 0.5em;
  }
  .LabelList{
    display: flex;
    flex: 1 1 auto;
  }
}