@mixin fontSize {
  @media (min-width: 1400px) {
    font-size: 16px;
  }
  @media (max-width: 1399px) {
    font-size: 14px;
  }
}

@mixin mobile {
  @media (max-width: 990px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 991px) {
    @content;
  }
}
